import React from 'react'
import { graphql } from 'gatsby'
import { Heading, Text } from '@pw/ui'

import { Layout, Container } from '../components/Layout'
import PostGrid from '../components/Posts/Grid'

export const CategoryDestinationTemplate = ({ data, location }) => {
  const { name, seo = {} } = data?.contentfulDestination || {}
  const posts = data?.allContentfulBlogPost?.edges || []

  return (
    <Layout
      headerTitle={name}
      location={location}
      seo={{
        ...seo,
        title: seo?.title || `${name} Wellness Travel`,
        description: seo?.description || `Wellness Travel posts related to ${name}`,
      }}
    >
      <Container pt="4" py="8" px={{ base: 0, md: 4 }} maxW="1200px">
        <Heading mb="4">{name}</Heading>
        <PostGrid posts={posts} />
      </Container>
    </Layout>
  )
}

export default CategoryDestinationTemplate

export const pageQuery = graphql`
  query CategoryDestinationBySlug($slug: String!) {
    contentfulDestination(slug: { eq: $slug }) {
      id
      slug
      name
      seo {
        ...seoFields
      }
    }

    allContentfulBlogPost(
      filter: { destination: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...postFields
        }
      }
    }
  }
`
